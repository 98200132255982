import React, { useEffect, useState } from 'react';
import {toaster, toastError} from "../../../core/toaster";
import { IPageLinkProps } from "../../page.props";
import { useNavigate } from "react-router-dom";
import {IMeeting, IMeetingRole, IMeetingTypes, IMeetingCandidatesAll} from "../../../api/models";
import { MeetingService } from "../../../api/meeting";
import { Loading } from "../../foundation/Loading";
import { ItemNav } from "../../form/ItemNav";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ItemNavGroup } from "../../form/ItemNavGroup";
import { useParamId, useParamRole } from "../../useParam";
import {Button, Input, Item, Select, FD, FDTNS, Form, Segment, Label} from "../../form";
import {SelectMulti} from "../../form/SelectMulti";
import {InputArea} from "../../form/InputArea";
import styles from './event_detail.module.scss';
import classNames from "classnames"; // Add this import
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faFileArrowDown, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import {getOrgMotherId} from "../../../util/roles";
import {MetaService} from "../../../api";
import {Upload} from "../../form/Upload";
import Papa from 'papaparse';
import printStyles from '../../../theme/print.module.scss';
import { EventSummaryPDFViewer } from './event_detail_roles_pdf';

const ROLES_NENNBAR = [2, 9] as number[];

const convertToCSV = (participants: IMeetingCandidatesAll[]): string => {
    const headers = [
        'JAMA ID',
        'Judomanager ID',
        'Familienname',
        'Vorname',
        'Geburtsdatum',
        'Alter Ende Jahr',
        'Geschlecht',
        'IOC',
        'Lizenz B',
        'Nation überprüft',
        'Medical',
        'Ausbildung Beschreibung',
        //'Ausbildung Name',
        'Rolle',
        'Org ID',
        'für Verein',
        'Landesverband',
        'Altersklasse',
        'Gewichtsklasse',
        'Einladung gültig bis',
        'Status',
        'JCard'
    ];

    const formatGender = (gender: 'm' | 'w' | 'd' | 'x'): string => {
        const genderMap: Record<string, string> = {
            'm': 'männlich',
            'w': 'weiblich',
            'd': 'divers',
            'x': 'keine Angabe'
        };
        return genderMap[gender] || gender;
    };

    const data = participants.map(p => ({
        'JAMA ID': p.jamaId,
        'Judomanager ID': p.judomanagerId,
        'Familienname': p.familyName,
        'Vorname': p.firstName,
        'Geburtsdatum': p.born,
        'Alter Jahresende': p.ageEndOfYear,
        'Geschlecht': formatGender(p.gender),
        'Altersklasse': p.AK,
        'Gewichtsklasse': p.GK,
        'IOC': p.ioc,
        'Lizenz B': p.licenseB,
        'Nationalität überprüft': p.nationChecked,
        'Medical': p.medical,
        'Grad': p.ausbDesc,
        //'Ausbildung Name': p.ausbName,
        'Rolle': p.role,
        'Org ID': p.orgId,
        'für Verein': p.role?.toLowerCase() === 'judoka' ? p.orgName : '--',
        'Landesverband': p.LV,
        'Einladung gültig bis': p.invitationTimeoutDate,
        'Status': p.status,
        'JCard': p.judoCard
    }));

    return Papa.unparse(data, {
        quotes: true,  // Quote all fields
        delimiter: ';', // Use semicolon for German Excel compatibility
        header: true,
    });
};

const handleExportCSV = async (orgId: number, meetingId: number, meetingName: string) => {
    try {
        // Get all candidates using the candidates_all method
        const allCandidates = await MeetingService.candidates_all(orgId, meetingId, true);

        // Convert the data to CSV
        const csv = convertToCSV(allCandidates);

        // Create and trigger download
        const blob = new Blob(['\ufeff' + csv], {
            type: 'text/csv;charset=utf-8;'
        });

        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', `Event_${meetingId}_Teilnehmer_${new Date().toISOString().split('T')[0]}.csv`);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Export failed:', error);
        toastError("Export fehlgeschlagen");
    }
};

export const EventDetailRoles: React.FC<IPageLinkProps> = ({ user, profile, gotoUrl, refresh, setRefresh }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [meeting, setMeeting] = useState<IMeeting | null>(null);
    const [allCandidates, setAllCandidates] = useState<IMeetingCandidatesAll[]>([]);
    const [showPdf, setShowPdf] = useState<boolean>(false);

    const examId = useParamId();
    const role = useParamRole(user);

    const meeting_fileUploads = meeting?.fileUploads || [];

    useEffect(() => {
        if (role?.orgId) {
            MeetingService.get(role.orgId, examId || 0).then((m) => {
                setMeeting(m);
                setLoading(false);
            });

            // Fetch all candidates for the PDF report
            MeetingService.candidates_all(role.orgId, examId || 0, true).then((candidates) => {
                setAllCandidates(candidates);
            });
        }
    }, [examId, role]);

    const togglePdfViewer = () => {
        setShowPdf(!showPdf);
    };

    if (loading) {
        return <div className={styles.loading}><Loading/></div>;
    }

    const editable = (
        meeting?.org_id === role.orgId ||
        role.orgId === 1 ||
        (meeting?.org_id && getOrgMotherId(meeting.org_id, profile.organizations) === role.orgId)
    )

    const meetingRoles = meeting?.param?.MEETINGROLES_REQ ||
        JSON.parse(meeting?.param?.MEETINGROLES || '[]') as number[];

    const filteredRoles = meetingRoles.filter(x => (meeting?.org_id === role.orgId || meeting?.org_mutter_id === role.orgId || 1=== role.orgId) || ROLES_NENNBAR.includes(x)); // 9=JUDOKA, 2=KUSRTEILNEHMER

    function getMeetingTypeNameById(meetingTypes: IMeetingTypes[], id: number): string | undefined {
        const meetingType = meetingTypes.find(mt => mt.id === id);
        return meetingType?.name;
    }

    let meetingTypeName;
    if (Array.isArray(profile.meetingTypes) && meeting?.meetingtype_id !== undefined && typeof meeting.meetingtype_id === 'number') {
        meetingTypeName = getMeetingTypeNameById(profile.meetingTypes, meeting.meetingtype_id);
    } else {
        meetingTypeName = 'Meeting Type not found';
    }

    const isRoleSelectEnabled = !(
        meeting?.open_from && meeting?.open_to && new Date(meeting.open_from) <= new Date() && new Date(meeting.open_to) >= new Date() ||
        (meeting?.org_id === role.orgId ||
            role.orgId === 1 ||
            (meeting?.org_id && getOrgMotherId(meeting.org_id, profile.organizations) === role.orgId))
    );

    const handleDelete = async () => {
        const gotoAfterDelete = `${gotoUrl}`
        if (!window.confirm('Sind Sie sicher, dass Sie dieses Event löschen möchten?')) {
            return;
        }

        return toaster(async () => {
            if (role?.orgId && examId) {
                await MeetingService.delete(role.orgId, examId);
                // Trigger refresh immediately after successful deletion
                if (setRefresh) {
                    setRefresh(prev => prev + 1);
                }
                navigate(gotoAfterDelete + '/0?t=delete'); // Navigate back to events list
                return undefined;
            }
            throw new Error("Keine Berechtigung zum Löschen");
        }, {
            success: "Event erfolgreich gelöscht",
            failure: "Löschen fehlgeschlagen"
        });
    };

    return (
        <>
            <ItemNavGroup label={`#${meeting?.id} ${meeting?.name || 'Ohne Namen'}`}>
                <Form>
                    <Item type="full" label="Veranstalter" size={6}>
                        <div className={styles.children}>{Array.isArray(profile.organizations) ?
                            profile.organizations.find(org => org.id === meeting?.org_id)?.nameShort : ''}

                        </div>
                    </Item>
                    <Item type="full" label="Meeting Type" size={6}>
                        <div className={styles.children}>
                            {meetingTypeName}
                        </div>
                    </Item>
                    <Item type="full" label="Altersklassen" size={12}>
                        <div className={styles.children}>
                            <div>{meeting?.ages_nennbar && typeof meeting.ages_nennbar === 'object' && Array.isArray(meeting.ages_nennbar.m)
                            && (meeting.ages_nennbar.m).length > 0 ? `M: ${meeting.ages_nennbar.m.join(', ')}` : 'keine AK für Herrn definiert'}</div>
                            <div>{meeting?.ages_nennbar && typeof meeting.ages_nennbar === 'object'
                            && Array.isArray(meeting.ages_nennbar.w) && (meeting.ages_nennbar.m).length > 0 ? `W: ${meeting.ages_nennbar.w.join(', ')}` : 'keine AK für Damen definiert'}</div>
                        </div>
                    </Item>
                    <Item type="full" label="Nennungzeitraum" size={6}>
                        <div className={styles.children}>{FDTNS(meeting?.open_from)} - {FDTNS(meeting?.open_to)}</div>
                    </Item>
                    <Item type="full" label="Event Datum" size={6}>
                        <div className={styles.children}>{FDTNS(meeting?.startdate)} - {FDTNS(meeting?.enddate)}</div>
                    </Item>

                    <Item type="full" label="Ort" size={12}>
                        <div className={styles.children}>{meeting?.param?.ORT || ''}</div>
                    </Item>

                    <Item type="full" label="Nationencheck?" size={6}>
                        <div className={styles.children}>{meeting?.param?.NATIONENCHECK ? "JA" : "Nein"}</div>
                    </Item>
                    <Item type="full" label="Bundesweite Nennung?" size={6}>
                        <div
                            className={styles.children}>{meeting?.param?.BUNDESWEIT === true ? "JA" : meeting?.param?.BUNDESWEIT === false ? "Nein" : '1x speichern!'}</div>
                    </Item>

                    <Item type="full" label="Url" size={6}>
                        <div className={styles.children}>{(meeting?.param?.URL || ' ')}</div>

                    </Item>
                    <Item type="full" label="Streaming Url" size={6}>
                        <div className={styles.children}>{(meeting?.param?.STREAMING_URL || ' ')}</div>

                    </Item>

                    <Item type="full" label="Anmerkungen">
                        <div className={styles.children}>{meeting?.param?.TEXT || ' '}</div>
                    </Item>
                    <Item type="full" label="Preis" size={6}>
                        <div className={styles.children}>{meeting?.param?.PRICING || ' '}</div>
                    </Item>
                    <Item type="full" label="veröffentlichen?" size={6}>
                        <div className={styles.children}>{meeting?.param?.PUBLISH_API ? "JA" : "Nein"}</div>
                    </Item>


                    <Item type="full" label="Anhänge/Auschreibung" size={12}>
                        <ul className={styles.ausschreibung}>
                            {meeting_fileUploads.map((file, index) => (
                                <li key={index}>
                                    <Label>
                                        <FontAwesomeIcon icon={faFileArrowDown} /><a href={MetaService.resolveCompetitionFileUrlAWS( file.name)}
                                                                                     target="_blank" download>{file.original}</a>
                                    </Label>
                                </li>
                            ))}
                        </ul>
                    </Item>
                </Form>

            </ItemNavGroup>

            <div className={styles.headerControls}>
                {editable &&
                    ( <>
                        <ItemNav
                            className={styles.button}
                            primary={true}
                            label={`Event Nr. ${examId} bearbeiten`}
                            icon={faEdit}
                            href={`${gotoUrl}/${examId}?t=edit`}
                            type={"list"}
                        />
                        <ItemNav
                            className={styles.deletebutton}
                            primary={true}
                            label="Event löschen"
                            icon={faTrash}
                            onClick={handleDelete}
                            type={"list"}
                        />
                    </>)
                }
                <ItemNav
                    className={styles.button}
                    onClick={() => {
                        if (role?.orgId && examId) {
                            handleExportCSV(role.orgId, examId, meeting?.name || 'Meeting');
                        } else {
                            toaster(() => {
                                throw new Error("Fehlende Daten");
                            }, {
                                failure: "Export nicht möglich - fehlende Daten"
                            });
                        }
                    }}
                    primary={true}
                    label="CSV-Export"
                    icon={faFileExport}
                    type={"list"}
                />
                <Button
                    className={classNames(styles.button, printStyles['hide-for-print'])}
                    onClick={togglePdfViewer}
                >
                    <FontAwesomeIcon icon={faFilePdf} /> Prüfliste als PDF
                </Button>
            </div>

            {showPdf && (
                <EventSummaryPDFViewer
                    meeting={meeting}
                    candidates={allCandidates}
                    onClose={togglePdfViewer}
                />
            )}

            <ItemNavGroup label='Anmeldung' className={styles.anmeldung}>
                <>{isRoleSelectEnabled && (
                    <ItemNav className={styles.info}
                             label={`Anmeldung bereits geschlossen. Für eine Übersicht der genannten JudoCards wähle "Judoka".`}/>
                )}</>
                {
                    filteredRoles.length > 0 ? filteredRoles.map(mr => {
                        const meetingRole = profile.meetingRoles.find(x => x.id === mr);

                        if (meetingRole) {
                            if (meetingRole.name == 'Judoka') {
                                if (meeting?.ages_nennbar && typeof meeting.ages_nennbar === 'object' &&
                                    (
                                        Array.isArray(meeting.ages_nennbar.m) && (meeting.ages_nennbar.m).length == 0
                                        && Array.isArray(meeting.ages_nennbar.w) && (meeting.ages_nennbar.m).length == 0
                                    )) {
                                    return <ItemNav
                                        className={styles.missingAk}
                                        key={meetingRole.name}
                                        type="list"
                                        label={`${meetingRole?.name}: Der Veranstalter hat noch keine Altersklassen definiert.`}
                                        disabled={false}
                                    />;
                                } else {
                                    return <ItemNav
                                        key={meetingRole.name}
                                        type="list"
                                        label={meetingRole?.name}
                                        onClick={() => navigate(`${gotoUrl}/${meeting?.id}?mr=${mr}&t=candidates`)}
                                        disabled={false}
                                    />;
                                }
                            } else {
                                return <ItemNav
                                    key={meetingRole.name}
                                    type="list"
                                    label={meetingRole?.name}
                                    onClick={() => navigate(`${gotoUrl}/${meeting?.id}?mr=${mr}&t=candidates`)}
                                    disabled={false}
                                />;
                            }
                        } else {
                            return <ItemNav key={mr} label={`MeetingRoleId ${mr} not available`}/>;
                        }
                    }) : (
                        meeting?.param?.BUNDESWEIT === true ? (
                            <ItemNav label="Bundesweite Nennung innerhalb des Nennzeitraums. Für diese Veranstaltung sind entweder noch keine Rollen angelegt
                                oder eine Anmeldung über JAMA ist nicht vorgesehen."/>
                        ) : <ItemNav
                            label="Noch nicht als Bundesweit gespeichern. Bitte Event im neuen JAMA einmalig speichern."/>
                    )}

            </ItemNavGroup>
        </>
    );
};