import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import 'moment/locale/de';
import { IMeeting, IMeetingCandidatesAll } from "../../../api/models";
import { Button } from "../../form";
import styles from './event_detail.module.scss';

import judoLogo from '../../../theme/editions/judoaustria/images/judo_logo.png';

// Format date for PDF
const formatPdfDate = (dateString: string): string => {
    if (!dateString) return '';
    return moment(dateString).format('DD.MM.YYYY');
};

// Define interface for EventSummaryPDF props
interface EventSummaryPDFProps {
    meeting: IMeeting | null;
    candidates: IMeetingCandidatesAll[];
}

// Format gender from code to text
const formatGender = (gender: string): string => {
    const genderMap: Record<string, string> = {
        'm': 'männlich',
        'w': 'weiblich',
        'd': 'divers',
        'x': 'keine Angabe'
    };
    return genderMap[gender] || gender;
};

// Define styles for PDF
const pdfStyles = StyleSheet.create({
    page: {
        padding: 40,
        paddingTop: 60,
        paddingLeft: 65, // Increase left padding
        fontSize: 12,
        position: 'relative',
    },
    header: {
        fontSize: 18,
        marginTop: 15,
        marginBottom: 10,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    summary: {
        marginBottom: 20,
        padding: 10,
        borderBottom: '1pt solid #ccc',
    },
    summaryRow: {
        flexDirection: 'row',
        marginBottom: 5,
    },
    summaryLabel: {
        width: '30%',
        fontWeight: 'bold',
    },
    summaryValue: {
        width: '70%',
    },
    roleGroup: {
        marginBottom: 15,
    },
    roleHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        //backgroundColor: '#f0f0f0',
        padding: 5,
        marginBottom: 5,
        marginTop: 10,
    },
    ageGroupHeader: {
        fontSize: 14,
        fontWeight: 'bold',
        padding: 5,
        backgroundColor: '#e0e0e0',
    },
    table: {
        width: '100%',
    },
    tableHeader: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        borderBottomStyle: 'solid',
        padding: 5,
        backgroundColor: '#f0f0f0',
        fontWeight: 'bold',
    },
    tableRow: {
        flexDirection: 'row',
        padding: 5,
    },
    tableRowEven: {
        backgroundColor: '#f1f1f1',
    },
    tableRowOdd: {
        backgroundColor: '#ffffff',
    },
    secondLine: {
        flexDirection: 'row',
        fontSize: 10,
        color: '#444',
        paddingLeft: 45, // Indent second line
    },
    // Columns for main row
    jamaIdCol: {
        width: '10%',
    },
    nameCol: {
        width: '33%',
    },
    firstnameCol: {
        width: '18%',
    },
    dateCol: {
        width: '14%',
    },
    genderCol: {
        width: '8%',
    },
    iocCol: {
        width: '10%',
    },
    judoCardCol: {
        width: '7%',
    },
    // Second line columns
    orgCol: {
        width: '45%',
    },
    nationCheckedCol: {
        width: '30%',
    },
    categoriesCol: {
        width: '25%',
    },
    pageNumber: {
        position: 'absolute',
        bottom: 20,
        right: 20,
        fontSize: 10,
        color: '#666',
    },
    fileName: {
        position: 'absolute',
        bottom: 20,
        left: 45,
        fontSize: 8,
        color: '#666',
    },
    logo: {
        position: 'absolute',
        top: 20,
        right: 20,
        width: '70px',
    },
});

// Define interfaces for grouped data
interface AkGroups {
    [key: string]: IMeetingCandidatesAll[];
}

interface GroupedParticipants {
    [role: string]: IMeetingCandidatesAll[] | AkGroups;
}

// Group and sort participants
function groupParticipants(candidates: IMeetingCandidatesAll[]): GroupedParticipants {
    // First group by role
    const roleGroups: Record<string, IMeetingCandidatesAll[]> = {};

    candidates.forEach(candidate => {
        const role = candidate.role || 'Unbekannt';
        if (!roleGroups[role]) {
            roleGroups[role] = [];
        }
        roleGroups[role].push(candidate);
    });

    // For each role group, sort or sub-group as needed
    const sortedGroups: GroupedParticipants = {};

    Object.keys(roleGroups).forEach(role => {
        if (role.toLowerCase() === 'judoka') {
            // For Judoka, group by AK
            const akGroups: AkGroups = {};

            roleGroups[role].forEach(judoka => {
                const ak = judoka.AK || 'Keine AK';
                if (!akGroups[ak]) {
                    akGroups[ak] = [];
                }
                akGroups[ak].push(judoka);
            });

            // Sort each AK group by GK and then by familyName
            const sortedAkGroups: AkGroups = {};

            Object.keys(akGroups).sort().forEach(ak => {
                sortedAkGroups[ak] = akGroups[ak].sort((a, b) => {
                    // First sort by GK
                    const gkA = a.GK || '';
                    const gkB = b.GK || '';
                    const gkCompare = gkA.localeCompare(gkB);

                    // If GK is the same, sort by familyName
                    if (gkCompare === 0) {
                        return (a.familyName || '').localeCompare(b.familyName || '');
                    }

                    return gkCompare;
                });
            });

            sortedGroups[role] = sortedAkGroups;
        } else {
            // For other roles, just sort by familyName
            sortedGroups[role] = roleGroups[role].sort((a, b) =>
                (a.familyName || '').localeCompare(b.familyName || '')
            );
        }
    });

    return sortedGroups;
}

// Create a PDF document component for event summary
export const EventSummaryPDF: React.FC<EventSummaryPDFProps> = ({ meeting, candidates }) => {
    // Group and sort the participants
    const groupedParticipants = groupParticipants(candidates);

    // Generate a meaningful filename
    const fileName = `teilnehmer-event-${meeting?.id}-${new Date().toISOString().split('T')[0]}.pdf`;

    return (
        <Document>
            <Page size="A4" style={pdfStyles.page}>
                {/* Logo in the top right corner */}
                <Image src={judoLogo} style={pdfStyles.logo} fixed />

                {/* Header */}
                <Text style={pdfStyles.header}>
                    Event #{meeting?.id} - {meeting?.name} - Teilnehmerliste
                </Text>

                {/* Event summary */}
                <View style={pdfStyles.summary}>
                    <View style={pdfStyles.summaryRow}>
                        <Text style={pdfStyles.summaryLabel}>Veranstalter:</Text>
                        <Text style={pdfStyles.summaryValue}>{meeting?.name}</Text>
                    </View>
                    <View style={pdfStyles.summaryRow}>
                        <Text style={pdfStyles.summaryLabel}>Zeitraum:</Text>
                        <Text style={pdfStyles.summaryValue}>
                            {formatPdfDate(meeting?.startdate || '')} - {formatPdfDate(meeting?.enddate || '')}
                        </Text>
                    </View>
                    <View style={pdfStyles.summaryRow}>
                        <Text style={pdfStyles.summaryLabel}>Ort:</Text>
                        <Text style={pdfStyles.summaryValue}>{meeting?.param?.ORT || ''}</Text>
                    </View>
                    <View style={pdfStyles.summaryRow}>
                        <Text style={pdfStyles.summaryLabel}>Teilnehmer:</Text>
                        <Text style={pdfStyles.summaryValue}>{candidates.length}</Text>
                    </View>
                </View>

                {/* Role groups */}
                {Object.keys(groupedParticipants).sort().map(role => {
                    if (role.toLowerCase() === 'judoka') {
                        // For Judoka, we have AK groups
                        const akGroups = groupedParticipants[role] as AkGroups;
                        const totalJudokas = Object.values(akGroups).reduce((sum, group) => sum + group.length, 0);

                        return (
                            <View key={role} style={pdfStyles.roleGroup}>
                                <Text style={pdfStyles.roleHeader}>{role} ({totalJudokas})</Text>

                                {/* For Judoka role, we render by age groups */}
                                {Object.keys(akGroups).sort().map(ak => (
                                    <View key={ak}>
                                        <Text style={pdfStyles.ageGroupHeader}>
                                            Altersklasse: {ak} ({akGroups[ak].length})
                                        </Text>

                                        <View style={pdfStyles.table}>
                                            {/* Table header */}
                                            <View style={pdfStyles.tableHeader}>
                                                <Text style={pdfStyles.jamaIdCol}>JAMA ID</Text>
                                                <Text style={pdfStyles.nameCol}>Nachname</Text>
                                                <Text style={pdfStyles.firstnameCol}>Vorname</Text>
                                                <Text style={pdfStyles.dateCol}>Geb.</Text>
                                                <Text style={pdfStyles.genderCol}>m/w</Text>
                                                <Text style={pdfStyles.iocCol}>Nat/Lic</Text>
                                                <Text style={pdfStyles.judoCardCol}>JC</Text>
                                            </View>

                                            {/* Table rows */}
                                            {akGroups[ak].map((participant: IMeetingCandidatesAll, index: number) => (
                                                <View key={`${participant.jamaId}-${participant.GK}-${index}`}>
                                                    {/* Main row */}
                                                    <View style={[
                                                        pdfStyles.tableRow,
                                                        index % 2 === 0 ? pdfStyles.tableRowEven : pdfStyles.tableRowOdd
                                                    ]}>
                                                        <Text style={pdfStyles.jamaIdCol}>{participant.jamaId}</Text>
                                                        <Text style={pdfStyles.nameCol}>{participant.familyName || ''}</Text>
                                                        <Text style={pdfStyles.firstnameCol}>{participant.firstName || ''}</Text>
                                                        <Text style={pdfStyles.dateCol}>{formatPdfDate(participant.born || '')}</Text>
                                                        <Text style={pdfStyles.genderCol}>{participant.gender || ''}</Text>
                                                        <Text style={pdfStyles.iocCol}>
                                                            {participant.ioc || ''}{participant.licenseB !== '--' ? '/' + participant.licenseB : ''}
                                                        </Text>
                                                        <Text style={pdfStyles.judoCardCol}>{participant.judoCard || ''}</Text>
                                                    </View>

                                                    {/* Second line with organization, nation checked, and categories */}
                                                    <View style={[
                                                        pdfStyles.secondLine,
                                                        index % 2 === 0 ? pdfStyles.tableRowEven : pdfStyles.tableRowOdd
                                                    ]}>
                                                        <Text style={pdfStyles.orgCol}>{participant.orgName || ''}</Text>
                                                        <Text style={pdfStyles.nationCheckedCol}>
                                                            {participant.nationChecked ? `Nation OK: ${formatPdfDate(participant.nationChecked)}` : ''}
                                                        </Text>
                                                        <Text style={pdfStyles.categoriesCol}>
                                                            {participant.AK ? `${participant.AK}` : ''}
                                                            {participant.GK ? ` / ${participant.GK}` : ''}
                                                        </Text>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                    </View>
                                ))}
                            </View>
                        );
                    } else {
                        // For other roles, we just have a simple sorted list
                        const participants = groupedParticipants[role] as IMeetingCandidatesAll[];

                        return (
                            <View key={role} style={pdfStyles.roleGroup}>
                                <Text style={pdfStyles.roleHeader}>{role} ({participants.length})</Text>

                                <View style={pdfStyles.table}>
                                    {/* Table header */}
                                    <View style={pdfStyles.tableHeader}>
                                        <Text style={pdfStyles.jamaIdCol}>JAMA ID</Text>
                                        <Text style={pdfStyles.nameCol}>Nachname</Text>
                                        <Text style={pdfStyles.firstnameCol}>Vorname</Text>
                                        <Text style={pdfStyles.dateCol}>Geb</Text>
                                        <Text style={pdfStyles.genderCol}>m/w</Text>
                                        <Text style={pdfStyles.iocCol}>Nat/Liz</Text>
                                        <Text style={pdfStyles.judoCardCol}>JC</Text>
                                    </View>

                                    {/* Table rows */}
                                    {participants.map((participant: IMeetingCandidatesAll, index: number) => (
                                        <View key={`${participant.jamaId}-${index}`}>
                                            {/* Main row */}
                                            <View style={[
                                                pdfStyles.tableRow,
                                                index % 2 === 0 ? pdfStyles.tableRowEven : pdfStyles.tableRowOdd
                                            ]}>
                                                <Text style={pdfStyles.jamaIdCol}>{participant.jamaId}</Text>
                                                <Text style={pdfStyles.nameCol}>{participant.familyName || ''}</Text>
                                                <Text style={pdfStyles.firstnameCol}>{participant.firstName || ''}</Text>
                                                <Text style={pdfStyles.dateCol}>{formatPdfDate(participant.born || '')}</Text>
                                                <Text style={pdfStyles.genderCol}>{participant.gender || ''}</Text>
                                                <Text style={pdfStyles.iocCol}>
                                                    {participant.ioc || ''}{participant.licenseB !== '--' ? '/' + participant.licenseB : ''}
                                                </Text>
                                                <Text style={pdfStyles.judoCardCol}>{participant.judoCard || ''}</Text>
                                            </View>

                                            {/* Second line with organization and nation checked */}
                                            <View style={[
                                                pdfStyles.secondLine,
                                                index % 2 === 0 ? pdfStyles.tableRowEven : pdfStyles.tableRowOdd
                                            ]}>
                                                <Text style={pdfStyles.orgCol}>{participant.orgName || ''}</Text>
                                                <Text style={pdfStyles.nationCheckedCol}>
                                                    {participant.nationChecked ? `Nation OK: ${formatPdfDate(participant.nationChecked)}` : ''}
                                                </Text>
                                                <Text style={pdfStyles.categoriesCol}>
                                                    {participant.ausbDesc || ''}
                                                </Text>
                                            </View>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        );
                    }
                })}

                {/* Filename at the bottom-left */}
                <Text style={pdfStyles.fileName} fixed>
                    {fileName}
                </Text>

                {/* Page number at the bottom-right */}
                <Text
                    style={pdfStyles.pageNumber}
                    render={({ pageNumber, totalPages }) => `Seite ${pageNumber} von ${totalPages}`}
                    fixed
                />
            </Page>
        </Document>
    );
};

// Define interface for EventSummaryPDFViewer props
interface EventSummaryPDFViewerProps {
    meeting: IMeeting | null;
    candidates: IMeetingCandidatesAll[];
    onClose: () => void;
}

// PDF Viewer Component with modal
export const EventSummaryPDFViewer: React.FC<EventSummaryPDFViewerProps> = ({ meeting, candidates, onClose }) => {
    return (
        <div className={styles.pdfViewer}>
            <div className={styles.pdfViewerHeader}>
                <h3>PDF Vorschau - Event #{meeting?.id} {meeting?.name}</h3>
                <Button
                    className={styles.closePdfButton}
                    onClick={onClose}
                >
                    <FontAwesomeIcon icon={faRemove} />Schließen
                </Button>
            </div>
            <div className={styles.pdfViewerContent}>
                <PDFViewer width="100%" height="100%">
                    <EventSummaryPDF
                        meeting={meeting}
                        candidates={candidates}
                    />
                </PDFViewer>
            </div>
        </div>
    );
};